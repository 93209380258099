const path = require("path");

require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
});

module.exports = {
  siteMetadata: {
    name: "storyblok starter",
    siteUrl: "http://127.0.0.1:8000",
    title: "starter",
    description: "starter",
    seo: {
      separator: "-",
      og: {
        // fbAppId: '123',
        type: "website",
        title: "starter",
        description: "",
        // image: '/img/og-cover.png',
      },
      twitter: {
        creator: "bejamas",
        // card: 'summary_large_image',
      },
    },
  },
  plugins: [
    "gatsby-plugin-styled-components",
    "gatsby-plugin-react-helmet",
    // 'gatsby-plugin-offline',
    "gatsby-plugin-remove-serviceworker",
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `img`,
        path: path.join(__dirname, `static`, `img`),
      },
    },
    "gatsby-plugin-svgr",
    "gatsby-transformer-sharp",
    "gatsby-transformer-remark",
    "gatsby-plugin-sharp",
    "gatsby-plugin-robots-txt",
    "gatsby-plugin-sitemap",
    {
      resolve: "gatsby-plugin-typography",
      options: {
        pathToConfigModule: "src/utils/typography.js",
        omitGoogleFont: true,
      },
    },
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        name: "starter",
        short_name: "str",
        start_url: "/",
        background_color: "#fff",
        theme_color: "#13233C",
        display: "minimal-ui",
        icon: "static/img/icon_310x310.png", // This path is relative to the root of the site.
        include_favicon: true, // Include favicon
      },
    },
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
        homeSlug: "home",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
      },
    },
    `gatsby-plugin-netlify`,
  ],
};
